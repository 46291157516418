.example::-webkit-scrollbar {
  display: none;
}
.escapeballs-container {
  font-family: "8Bit";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.starfield {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
.screenshot-wrapper {
  flex-direction: row;
  width: 75%;
  padding: 0;
}
.screenshot-container {
  max-width: 400px;
}

.ss-description {
  padding: 30px;
  font-size: 16px;
  margin-top: -100px;
  line-height: 25px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  background-color: none;
  text-align: left;
}
.app-store-container {
  position: absolute;
  z-index: -1;
}
}

/* If the screen size is 600px wide or less... */
@media screen and (max-width: 600px) {
  .screenshot-wrapper {
    flex-direction: column;
  }

  .ss-description {
    margin-top: -100px;
    width: 65%;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 50px;
    font-size: 12.5px;
    line-height: 20px;
    background-color: black;
  }

}

.screenshot-container {
  display: block;
  justify-content: center;
  min-width: 225px;
  align-items: center;
  width: 90vw;


}
.escapeballs-header {
  font-family: "8Bit";
  font-size: 1.75em;
  color: white;
  margin-bottom: 50px;
}
.screenshot {
  height: auto;
  border: gray 5px solid;
  border-radius: 25px;
  width: 50%;
  max-width: 250px;
  min-width: 150px;
}

.ss-description {
  font-family: "8Bit";
  color: white;

}

.screenshot-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

}

.app-store-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.app-store-container div {
  z-index: -1;
}

.app-store-icon {
  margin-top: 10px;


}




