.scandish-reels-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-image: radial-gradient(ellipse at center bottom, rgb(162, 155, 139) 0%, black 100%);
    background-size: cover cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
  }
  
  /* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
  @media screen and (min-width: 601px) {
    .ytdownloader-header {
      font-size: 45px;
  
    }
    }
  
    /* If the screen size is 600px wide or less... */
  @media screen and (max-width: 600px) {
    .ytdownloader-header {
      font-size: 1.9em;
  
    }
  }
  
  .ytdownloader-header {
    margin-top: 50px;
    color: white;
    margin-bottom: 0px;
    
  }
  
  
  .ytdownloader-header a:visited {
    color: white;
  
  }
  
  
  .ytdownloader-mockup {
    max-width: 700px;
    margin-top: 20px;
    margin-right: 25px;
    object-fit: cover;
    width: 100vw;
    z-index: -1;
  }
  
  .ytdownloader-description {
  
  }
  
  .ytdownlaoder-collab-text {
    margin: 0;
    color: white;
  
  }
  
  .ytdownlaoder-collab-text a{
    color: rgb(137, 211, 249);
  }
  
  .ytdownlaoder-collab-text a:visited{
    color: rgb(137, 211, 249);
  }
  
  .scandish-visit-button {
    color: rgb(237,227,203);
    font-weight: 700;
    font-size: 18px;
    vertical-align:bottom;
    border:rgb(237,227,203) solid 2px;
    padding: 10px;
    width: 5em;
    transition: all 0.1s linear;
    margin: 10px;
  }
  
  .scandish-visit-button:hover {
    border-color: white;
    color: white;
    cursor: pointer;
  }
  